<template>
  <div class="input-field">
    <label :for="id">{{label}}</label>
    <input :type="type ? fieldType : 'text'" :disabled="disabled" :placeholder="placeholder" :autocomplete="autocomplete" :value="modelValue" @change="$emit('update:modelValue', $event.target.value)">
    <div v-if="type=='password'" class="input-field-passwordToggle" :class="reveal ? 'is-active' : ''" @click="togglePasswordShow"></div>
    <span class="hint-text" v-if="hintText">{{hintText}}</span>
  </div>
</template>

<script>

export default {
  name: 'UiInput',
  props: {
    disabled: Boolean,
    modelValue: String,
    id: String,
    type: String,
    placeholder: String,
    autocomplete: String,
    label: String,
    hintText: String
  },
  methods: {
    togglePasswordShow() {
      console.log(this.reveal)
      if (this.reveal) {
        this.reveal = false;
        this.fieldType = 'password';
      } else {
        this.reveal = true;
        this.fieldType = 'text';
      }
    }
  },
  data() {
    return {
      fieldType: this.type,
      reveal: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  label {
    font: normal 15px/1 'CircularXXWeb-Medium', sans-serif;
    color: #2C3A43;
    display: block;
  }
  input {
    font: normal 16px/48px 'CircularXXWeb-Book', sans-serif;
    border: 1px solid #E3E5EA;
    border-radius: 8px;
    color: #2C3A43;
    padding: 0 16px;
    margin: 8px 0 0;
    transition: border-color 0.25s;
    width: 100%;
    box-sizing: border-box;
  }
  .input-field {
    position: relative;
  }
  .input-field + .input-field {
    margin-top: 24px;
  }
  input:focus {
    border-color: #A0A7AD;
    outline: none;
  }
  .input-field + .input-field {
    margin-top: 20px;
  }
  input:disabled {
    background: #f2f2f4;
  }
  .input-field-passwordToggle {
    position: absolute;
    top: 36px;
    right: 30px;
    width: 24px;
    height: 24px;
    background-image: url('/icons/icon-password-hidden.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .input-field-passwordToggle.is-active {
    background-image: url('/icons/icon-password-open.png');
  }
  .hint-text {
    color: #838F9E;
    display: block;
    margin-top: 8px;
    font: normal 13px/1.4 'CircularXXWeb-Book', sans-serif;
  }
</style>
