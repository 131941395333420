
<template>
  <div>
    <form id="uploadForm">
      <label for="profileImage">
        <div class="avatar">
            <Profile :icon="userData.avatar" :name="userData.name"/>
          <div class="avatar-text">
            <div class="avatar-text-header">Byt profilbild</div>
            <div class="avatar-text-body">Här kan du ladda upp en egen profilbild.</div>
          </div>
          <div v-if="!sending" class="avatar-progress" :style="progress"></div>
        </div>
      </label>
      <input type="text" name="ref" value="user" />
      <input type="text" name="refId" :value="userData.id" />
      <input type="text" name="field" value="avatar" />
      <input type="text" name="source" value="users-permissions" />
      <input id="profileImage" type="file" name="files" accept=".jpg,.jpeg,.png" @change="upload"/>
    </form>
    <div class="editable">
      <div class="editable-item" @click="edit('name', userData.name)">
        <div class="editable-item-info">
          <div class="editable-item-info-label">Namn</div>
          <div class="editable-item-info-value">{{ userData.name }}</div>
        </div>
        <div class="editable-item-action"></div>
      </div>

      <div v-if="userData.provider == 'local'" class="editable-item" @click="edit('email', userData.email)">
        <div class="editable-item-info">
          <div class="editable-item-info-label">E-postadress</div>
          <div class="editable-item-info-value">{{ userData.email }}</div>
        </div>
        <div class="editable-item-action"></div>
      </div>
      <div v-if="userData.provider == 'local'" class="editable-item" @click="edit('password')">
        <div class="editable-item-info">
          <div class="editable-item-info-label">Lösenord</div>
          <div class="editable-item-info-value">••••••••</div>
        </div>
        <div class="editable-item-action"></div>
      </div>

      <div v-if="userData.provider != 'local'" class="editable-item no-hover">
        <div class="editable-item-info">
          <div class="editable-item-info-label">E-postadress</div>
          <div class="editable-item-info-value">{{ userData.email }}</div>
        </div>
      </div>
      <div v-if="userData.provider != 'local'" class="editable-item no-hover">
        <div class="editable-item-info">
          <div class="editable-item-info-label">Koppling</div>
          <div class="editable-item-info-value">{{ providerName }}</div>
        </div>
      </div>

      <div class="editable-item" @click="triggerPanel('survey')">
        <div class="editable-item-info">
          <div class="editable-item-info-label">Varför vill du meditera?</div>
          <div class="editable-item-info-value">Anpassa ditt rekommenderade innehåll</div>
        </div>
        <div class="editable-item-action"></div>
      </div>

      <div class="editable-item" @click="triggerPanel('subscriptions')">
        <div class="editable-item-info">
          <div class="editable-item-info-label">Abonnemang</div>
          <div class="editable-item-info-value">{{ subscriptionName  }}</div>
        </div>
        <div class="editable-item-action"></div>
      </div>
    </div>
    <div v-if="editForm" class="dialog">
      <div class="dialog-container">
        <ui-input :type="editFieldType" :label="editFieldLabelsMap[editFieldLabel]" :modelValue="editFieldValue" v-model="editFieldValue"/>
        <div class="dialog-container-buttons">
          <ui-button :loading="sending" @click="save">Spara</ui-button>
          <ui-button :loading="sending" class="secondary" @click="cancel">Avbryt</ui-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Mindfully from './common';
  import Profile from './components/Profile.vue';
  import UiButton from './components/Button.vue';
  import UiInput from './components/Input.vue';
  export default {
    name: "UserSettings",
    props: {
      user: Object,
      subscriptionName: String,
      subscriptions: Array
    },
    data() {
      return {
        editForm: false,
        editFieldType: 'text',
        editFieldLabel: '',
        editFieldValue: '',
        editFieldLabelsMap: {
          'name': 'Uppdatera ditt namn',
          'email': 'Uppdatera din e-post',
          'password': 'Ändra ditt lösenord'
        },
        subscriptionNames: {
          'life': 'Livstid',
          'yearly': 'Årsvis',
          'monthly': 'Månadsvis'
        },
        uploadProgress: 0,
        sending: false,
        userData: this.user
      }
    },
    components: {
      Profile,
      UiButton,
      UiInput
    },
    computed: {
      progress() {
        return 'width:' + this.uploadProgress + '%';
      },
      providerName() {
        var providers = {
          "facebook": "Facebook",
          "apple": "Apple Sign-in",
          "google": "Google"
        }
        return providers[this.userData.provider] ? providers[this.userData.provider] : this.userData.provider;
      }
    },
    methods: {
      edit(fieldLabel, fieldValue) {
        this.editFieldType = (fieldLabel == 'password' ? 'password' : 'text');
        this.editFieldLabel = fieldLabel;
        this.editFieldValue = fieldValue;
        this.editForm = true;
      },
      cancel() {
        this.editForm = false;
        this.editFieldType = 'text';
        this.editFieldLabel = '';
        this.editFieldValue = '';
      },
      upload() {
        if (this.sending) return;
        this.sending = true;

        const formElement = document.querySelector('form');

        const request = new XMLHttpRequest();

        request.open('POST', process.env.VUE_APP_API_URL + '/upload');
        request.setRequestHeader("Authorization", 'Bearer ' + Mindfully.fetchItem('m-ut'));
        request.onprogress = (event) => {
          var position = event.loaded || event.position;
          if (event.lengthComputable) {
              this.uploadProgress = Math.ceil(position / event.total * 100);
          }
        };
        request.onreadystatechange = () => {
          if(request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 0 || request.status === 200) {
              const response = JSON.parse(request.responseText);
              this.userData.avatar = {
                url: response[0].url
              }
              Mindfully.storeItem('mindfullyUserData', this.userData, 60);
            } else {
              Mindfully.defaultErrorHandler(request.responseText);
            }
            this.sending = false;
            this.uploadProgress = 0;
          }
        };
        request.send(new FormData(formElement));
      },
      save() {
        if (this.sending) return;
        this.sending = true;

        let update = {};
        update[this.editFieldLabel] = this.editFieldValue;

        Mindfully.sendEvent('change_' + this.editFieldLabel);

        Mindfully.post({
          url: "/users/me",
          body: update
        }).then((json) => {
          Mindfully.storeItem('mindfullyUserData', json, 60);
          this.sending = false;
          this.editForm = false;
          this.userData = json;
        }).catch((err) => {
          Mindfully.defaultErrorHandler(err);
          this.sending = false;
        })
      },
      triggerPanel(panelName) {
        this.$emit('trigger-panel', null, panelName);
      }
    }
  }
</script>

<style scoped>
  #profileImage {
    opacity: 0;
  }
  #uploadForm {
    margin: 0 24px 8px;
  }
  #uploadForm input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    height: 0;
    padding: 0;
  }
  .avatar {
    display: flex;
    position: relative;
    background-color: #fcf1e2;
    transition: 0.2s background-color;
    padding: 16px;
    border-radius: 8px;
    overflow: hidden;
  }
  .avatar:hover {
    cursor: pointer;
    background-color: #FBECD9;
  }
  .avatar-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 10px;
    background-color: #3c635a;
  }
  .avatar-text {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    justify-content: center;
  }
  .avatar-text-header {
    color: #F6BF7F;
  }
  .avatar-text-body {
    color: #444;
  }
  .profile {
    margin: 0;
    height: 70px;
    width: 70px;
    min-width: 70px;
  }
  .editable-item {
    display: flex;
    padding: 0 24px;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    transition: 0.2s background-color;
    border-bottom: 1px solid #E3E5EA;
  }
  .editable-item:hover {
    background-color: #f2f2f4;
    cursor: pointer;
  }
  .editable-item.no-hover {
    background-color: #fff;
    cursor: default;
  }
  .editable-item-info {
    padding: 20px 0;
  }
  .editable-item-info-label {
    font-family: 'CircularXXWeb-Medium', sans-serif;
  }
  .editable-item-info-value {
    height: 20px;
    margin-top: 4px;
    color: #838F9E;
  }
  .editable-item-action {
    width: 24px;
    height: 24px;
    background-image: url('/icons/icon-edit.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .dialog {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    background-color: rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog button {
    margin-top: 8px;
  }
  .dialog-container {
    flex-grow: 1;
    margin: 16px;
    padding: 24px;
    background-color: #fff;
  }
  .dialog-container-buttons {
    display: flex;
    justify-content: space-between;
  }
</style>