<template>
  <div class="dimming">
    <div class="alert">
      <header>
        <span class="close" @click="close"><svg width="26" height="26" xmlns="http://www.w3.org/2000/svg"><g fill="#2C3A43" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M20.97 3.97a.75.75 0 011.133.976l-.073.084-17 17a.75.75 0 01-1.133-.976l.073-.084 17-17z"/><path d="M3.97 3.97a.75.75 0 01.976-.073l.084.073 17 17a.75.75 0 01-.976 1.133l-.084-.073-17-17a.75.75 0 010-1.06z"/></g></svg></span>
        <h2>{{header}}</h2>
      </header>
      <section v-html="text"></section>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UiAlert',
  props: {
    header: String,
    text: String
  },
  methods: {
    close() {
      this.$emit('close-alert');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  header,
  section {
    padding: 32px 24px 24px;
    text-align: left;
  }
  header .close {
    display: inline-block;
    margin: -4px;
    padding: 4px;
    cursor: pointer;
  }
  header .close svg {
    display: block;
  }
  header .settings {
    cursor: pointer;
  }
  header h2 {
    color: #2C3A43;
    margin: 5px 0 0;
    font: normal 32px/36px 'CircularXXWeb-Medium', sans-serif;
    text-align: center;
  }
  .dimming {
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 9999999;
  }
  .alert {
    background: #fff;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    max-width: 480px;
    max-height: 800px;
    overflow: hidden;
    box-shadow: 0 6px 20px rgba(8,7,41,0.06);
    color: #2C3A43;
    font: normal 15px/20px 'CircularXXWeb-Book', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>
