<template>
  <button :class="[type, { loading }]">
    <transition name="fade"><span class="spinner" v-if="loading"></span></transition>
    <slot></slot>
  </button>
</template>

<script>

export default {
  name: 'UiButton',
  props: {
    label: String,
    loading: Boolean,
    type: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  button {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'CircularXXWeb-Medium', sans-serif;
    display: inline-block;
    background: #F6BF7F;
    color: #FFF;
    font-size: 15px;
    text-decoration: none;
    -webkit-appearance: none;
    border: 0;
    margin: 0;
    padding: 0 20px;
    border-radius: 8px;
    height: 48px;
    line-height: 48px;
    transition: background-color 0.25s, color 0.5s;
    cursor: pointer;
    position: relative;
  }
  button:hover, button:focus {
    background-color: #E9B578;
  }
  button:active {
    background-color: #DBAA71;
  }
  button.loading {
    background: #F6BF7F;
    color: rgba(255,255,255,0);
  }
  button.loading .spinner {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    background: conic-gradient(rgba(255,255,255,0), rgba(255,255,255,1));
    border-radius: 50%;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation: spin 0.6s both infinite linear;
  }
  button.loading .spinner:after {
    content: "";
    position: absolute;
    height: 16px;
    width: 16px;
    background: #F6BF7F;
    border-radius: 50%;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  button.secondary {
    background: transparent;
    border: 1px solid #F6BF7F;
    color: #F6BF7F;
  }
  button.secondary:hover, button.secondary:focus {
    background: rgba(219, 170, 113, 0.05);
  }
  button.secondary:active {
    background: rgba(219, 170, 113, 0.1);
  }
  button.link {
    background: rgba(219, 170, 113, 0.0);
    color: #F6BF7F;
    border: 0;
  }
  button.link:hover, button.link:focus {
    background: rgba(219, 170, 113, 0.1);
  }
  button.link:active {
    background: rgba(219, 170, 113, 0.2);
  }
  button >>> .icon {
    display: inline-block;
    margin: 0 8px 2px 0;
    vertical-align: middle;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
      opacity: 0;
  }
  @keyframes spin {
    from {
      transform: rotateZ(0deg);
    }
    to {
      transform: rotateZ(360deg);
    }
  }
</style>
