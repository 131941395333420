<template>
  <div class="container">
    <p>Välj det val som passar bäst just nu. Du kan förstås utforska alla delar i appen.</p>
    <div class="answers">
      <div v-for="(answer, index) in answers" :key="answer.id" :class="{'answer': true, 'answer--isActive': index == currentSelected}" @click="currentSelected = index">
        <img :src="icons[index]" />
        <label>{{answer.text}}</label>
      </div>
    </div>
    <ui-button :loading="sending" @click="send">{{afterRegisterFlow?'Fortsätt':'Spara'}}</ui-button>
    <ui-button v-if="afterRegisterFlow" type="secondary" @click="$emit('next')">Hoppa över</ui-button>
  </div>
</template>

<script>
  import Mindfully from './common';
  import UiButton from './components/Button.vue';
  export default {
    name: "UserSubscriptions",
    emits: ['next'],
    props: {
      afterRegisterFlow: Boolean
    },
    data() {
      return {
        user: null,
        icons: [
          require('./assets/sa-0.svg'),
          require('./assets/sa-1.svg'),
          require('./assets/sa-2.svg'),
          require('./assets/sa-3.svg'),
          require('./assets/sa-4.svg'),
          require('./assets/sa-5.svg')
        ],
        currentSelected: -1,
        sending: false,
        answers: [
          {
            text: "Lära mig att meditera",
            id: 'beginner'
          },
          {
            text: "Hantera stress & oro",
            id: 'stress'
          },
          {
            text: "Förbättra sömn",
            id: 'sleep'
          },
          {
            text: "Öka fokus",
            id: 'focus'
          },
          {
            text: "Mer kärlek & medkänsla",
            id: 'love'
          },
          {
            text: "Fördjupa min praktik",
            id: 'advanced'
          }
        ]
      }
    },
    components: {
      UiButton
    },
    methods: {
      send() {
        if (this.sending || this.currentSelected == -1) return;
        this.sending = true;

        Mindfully.sendEvent('change_goal');
        Mindfully.post({
          url: "/users/me",
          body: {
            goal: this.answers[this.currentSelected].id
          }
        }).then((json) => {
          Mindfully.storeItem('mindfullyUserData', json, 60);
          if (this.afterRegisterFlow) {
            this.$emit('next');
          } else {
            alert("Dina ändringar har sparats");
          }
          this.sending = false;
        }).catch((err) => {
          Mindfully.defaultErrorHandler(err);
          this.sending = false;
        })
      }
    },
    computed: {

    },
    mounted() {
      this.user = Mindfully.fetchItem('mindfullyUserData');
      if (this.user && this.user.goal) {
        this.answers.forEach((answer, index) => {
          if (answer.id == this.user.goal) {
            this.currentSelected = index;
          }
        });
      }
    }
  }
</script>

<style scoped>

  .container {
    padding: 0 24px 24px;
    display: flex;
    flex-direction: column;
  }
  .container p {
    margin: 0;
  }

  button + button {
    margin-top: 16px;
  }

  .answers {
    display: flex;
    justify-content: space-between;
    margin: 16px -8px;
    flex-wrap: wrap;
  }
  .answers--inActive .answer {
    cursor: normal;
    pointer-events: none;
    opacity: 0.5;
  }
  .answer {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #fff;
    width: calc(50% - 16px);
    height: 128px;
    margin: 8px;
    box-sizing: border-box;
    padding: 0 8px;
    border-radius: 8px;
    border: 2px solid #fff;
    color: #2C3A43;
    cursor: pointer;
    font-size: 15px;
    box-shadow: 0 6px 8px rgba(8,7,41,0.06);
    transition: 0.2s background-color, 0.2s border-color;
  }
  .answer img {
    position: absolute;
    top: 11px;
    left: 0;
    right: 0;
    width: 64px;
    height: 64px;
    margin: auto;
  }
  .answer label {
    font: normal 15px/1.2 'CircularXXWeb-Medium', sans-serif;
    top: 70px;
    left: 16px;
    right: 16px;
    bottom: 8px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .answer:hover {
    background-color: #FDF3E8;
  }
  .answer.answer--isActive {
    background-color: #fff;
    border-color: #F6BF7F;
  }

  .answer.answer--isActive:after {
    position: absolute;
    content: "";
    top: 10px;
    right: 10px;
    background: url(/icons/points.png) no-repeat center / 22px 22px;
    height: 22px;
    width: 22px;
  }
  .answer label {
    pointer-events: none;
  }
</style>