<template>
  <div class="sub">
    <div class="sub-intro">
      <p>
        Få tillgång till hela Mindfullys utbud med över 250 guidade meditationer på svenska gratis i {{ trialLength }} dagar. Provperioden övergår automatiskt i ett abonnemang.
      </p>
      <div class="usp" v-for="usp in usps" :key="usp.id">
        <span class="usp-icon"></span>
        <div class="usp-text">{{ usp }}</div>
      </div>
    </div>
    <div v-if="currentSubscriptionIndex === -1 && subscription && !subscriptionCanBeManaged" class="active-subscription">
      <span class="active-subscription-icon"></span>
      <div class="active-subscription-title">
        {{ subscriptionName }}
      </div>
      <div class="active-subscription-text">
        {{ 'Du har just nu ett abonnemang som är giltigt' +(subscription.end ? (' till ' + moment(subscription.end, 'LLL')) : ' tills vidare') }}
      </div>
    </div>
    <section>
      <div class="subscriptions" :class="(isActiveSubscriber && !webSubscription) ? 'subscriptions--inActive' : ''">
        <div class="subscription" :class="selectedSubscriptionIndex == key ? 'subscription--isActive' : ''" v-for="(subscription, key) in subscriptions" :key="key" @click="selection(key)">
          <div class="subscription-title">{{ subscription.title }}</div>
          <div :class="['subscription-price', {'applied-coupon': appliedCoupon(subscription.id)}]">{{ applyCoupon(subscription) + (appliedCoupon(subscription.id) && hasPriceDisclaimer ? "*" : "") }}</div>
          <div class="subscription-span">{{ subscription.period }}</div>
          <div class="subscription-label" v-if="subscription.label" >{{ subscription.label }}</div>
        </div>
      </div>
      <div v-if="disclaimer" class="disclaimer" v-html="markdown(disclaimer)"></div>
      <p v-if="!disclaimer" class="disclaimer">
        Du kan avsluta när som helst under provperioden utan att debiteras. Gäller endast nya prenumeranter. <span v-if="coupon">Priser kan variera beroende på erbjudandets villkor, du ser det slutgiltiga priset i nästa steg.</span>
      </p>
      <div v-if="subscriptionCanBeManaged" class="subscription-link">
        <a href="#" @click="handleSubscription">Hantera ditt abonnemang</a>
      </div>
      <ui-button :loading="sending" :disabled="selectedSubscriptionIndex == -1 || (isActiveSubscriber && !webSubscription && currentSubscriptionIndex === selectedSubscriptionIndex)" @click="subscribe">Fortsätt</ui-button>
      <ui-button v-if="afterRegisterFlow" type="secondary" @click="$emit('skip')">Hoppa över</ui-button>
    </section>
  </div>
</template>

<script>
  import moment from 'moment';
  import MarkdownIt from 'markdown-it';
  import { loadStripe } from '@stripe/stripe-js';
  import Mindfully from './common';
  import UiButton from './components/Button.vue';
  var md = new MarkdownIt();
  export default {
    name: "UserSubscriptions",
    props: {
      afterRegisterFlow: Boolean,
      subscription: Object,
      subscriptionName: String,
      subscriptions: Array
    },
    data() {
      return {
        coupon: null,
        trialLength: 30,
        subscriptionNames: {
          'life': 'Livstid',
          'yearly': 'Årsvis',
          'monthly': 'Månadsvis'
        },
        selectedSubscriptionIndex: -1,
        disclaimer: null,
        sending: false
      }
    },
    components: {
      UiButton
    },
    methods: {
      json(obj) {
        return JSON.stringify(obj);
      },
      moment(date, format) {
        const locale = navigator.language ? navigator.language : moment.locale();
        if (format) {
          return moment(date).locale(locale).format(format);
        } else {
          return moment(date).locale(locale);
        }
      },
      markdown(str) {
        return md.render(str);
      },
      selection(index) {
        this.selectedSubscriptionIndex = index;
      },
      currentSubscription() {
        if (this.isActiveSubscriber) {
          this.selectedSubscriptionIndex = this.currentSubscriptionIndex;
        }
      },
      async handleSubscription(event, upgrade) {
        if (this.subscription.provider == 'apple') {
          this.sending = false;
          window.location.href = 'https://apps.apple.com/account/subscriptions';
        } else if (this.subscription.provider == 'google') {
          this.sending = false;
          window.location.href = 'https://play.google.com/store/account/subscriptions?sku='+this.subscription.product_id+'&package=com.mindfully.app';
        } else if (this.subscription.provider == 'stripe') {
          Mindfully.post({
            url: "/stripe/create-customer-portal-session",
            body: {
              returnUrl: window.location.href
            }
          }).then((json) => {
            this.sending = false;
            if (!json.error) {
              window.location.href = json.url + (upgrade ? '/subscriptions/' + this.subscription.original_transaction_id + '/update' : '');
            } else {
              alert(json.message);
            }
          }).catch((err) => {
            Mindfully.defaultErrorHandler(err);
            this.sending = false;
          })
        }

        return false;
      },
      async validateNoSubscription() {
        var self = this;
        return new Promise(function(resolve) {
          Mindfully.get({
            url: "/users/me"
          }).then((json) => {
            if (json.subscription && json.subscription.provider == 'strapi' && (!json.subscription.end || moment(json.subscription.end).isAfter(moment()))) {
              self.handleSubscription(null, true);
              resolve(false);
            } else if (json.subscription && json.subscription.provider !== 'strapi' && (!json.subscription.end || moment(json.subscription.end).isAfter(moment()))) {
              alert("Vi kunde redan hitta ett abonnemang på ditt konto. Logga ut och in för att få tillgång till Mindfullys utbud.");
              resolve(false);
            } else {
              resolve(true);
            }
          }).catch((err) => {
            Mindfully.defaultErrorHandler(err);
            resolve(false);
          });
        });
      },
      async subscribe() {
        if (this.sending) return;
        if (this.isActiveSubscriber && this.subscription.provider == 'stripe') {
          return this.handleSubscription(null, true);
        }
        this.sending = true;

        var safe = await this.validateNoSubscription();

        if (!safe) {
          this.sending = false;
          return;
        }

        const stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);

        Mindfully.sendEvent('mindfully_start_purchase', { subscription: this.subscriptions[this.selectedSubscriptionIndex].title });

        Mindfully.post({
          url: "/stripe/create-session",
          body: {
            subscriptionId: this.subscriptions[this.selectedSubscriptionIndex].id,
            returnUrl: window.location.href
          }
        }).then((json) => {
          stripe
            .redirectToCheckout({
              sessionId: json.id
            })
            .then(() => {
              this.sending = false;
            });
        }).catch((err) => {
          Mindfully.defaultErrorHandler(err);
          this.sending = false;
        })
      },
      applyCoupon(sub) {
        var price = sub.price;
        if (this.coupon) {
          var shouldApply = false;
          if (this.coupon && this.coupon.applies_to) {
            shouldApply = this.coupon.applies_to.indexOf(sub.id) !== -1;
          } else if (this.coupon && !this.coupon.applies_to) {
            shouldApply = true;
          }
          if (shouldApply) {
            if (this.coupon.percent_off) {
              price = parseFloat(price) - parseFloat(price/100*this.coupon.percent_off);
            }
            if (this.coupon.amount_off) {
              price = parseFloat(price) - parseFloat(this.coupon.amount_off/100);
            }
          }
        }
        return price + " kr";
      },
      appliedCoupon(id) {
        if (this.coupon && this.coupon.applies_to) {
          return this.coupon.applies_to.indexOf(id) !== -1;
        } else if (this.coupon && !this.coupon.applies_to) {
          return true;
        }
        return false;
      }
    },
    computed: {
      usps() {
        return [
          'Välj abonnemang',
          'Meditera gratis i '+this.trialLength+' dagar',
          'Avsluta när du vill'
        ];
      },
      hasPriceDisclaimer() {
        return this.disclaimer && this.disclaimer.indexOf("*") !== -1 && this.coupon && this.coupon.is_global_discount;
      },
      subscriptionCanBeManaged() {
        return this.isActiveSubscriber && this.subscription && (this.subscription.provider == 'apple' || this.subscription.provider == 'google' || this.subscription.provider == 'stripe');
      },
      availableSubscriptions() {
        return this.subscriptions;
      },
      webSubscription() {
        return this.subscription && this.subscription.provider == "stripe";
      },
      mobileSubscription() {
        return this.subscription ? ['google', 'apple'].indexOf(this.subscription.provider) !== -1 : null;
      },
      isActiveSubscriber() {
        if (this.subscription && (!this.subscription.end || moment(this.subscription.end).isAfter(moment()))) {
          return true;
        } else {
          return false;
        }
      },
      subscriptionLinkText() {
        let text = null;
        if (this.subscription) {
          if (this.subscription.provider == 'stripe') {
            return 'Hantera ditt abonnemang';
          }
        }

        return text;
      },
      currentSubscriptionIndex() {
        let subscriptionIndex = -1;
        const mySubscription = this.subscription;
        if (mySubscription && this.subscriptions) {
          this.subscriptions.map((subscription, index) => {
            if (subscriptionIndex === -1 && [subscription.appleProductId, subscription.googleProductId, subscription.stripeProductId].indexOf(mySubscription.product_id) !== -1) {
              subscriptionIndex = index;
            }
          });
        }
        return subscriptionIndex;
      }
    },
    mounted() {
      this.coupon = Mindfully.fetchItem('mindfullyCoupon');
      this.disclaimer = Mindfully.fetchItem('mindfullyDisclaimer');
      var trialLength = Mindfully.fetchItem('mindfullyTrial');
      if (trialLength) {
        this.trialLength = trialLength;
      }
      if (this.subscriptions) {
        this.currentSubscription();
      }
    }
  }
</script>

<style scoped>
  button {
    width: 100%;
    margin-top: 24px;
  }
  button[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
  button + button {
    margin-top: 16px;
  }
  .sub {
    display: flex;
    flex-direction: column;
  }
  .sub-intro {
    padding: 0 24px 32px;
  }
  .sub-intro p {
    margin-top: 0;
  }
  section {
    flex-grow: 1;
    padding: 32px 24px;
    background-color:#f2f2f4;
  }
  .disclaimer {
    color: #838F9E;
    text-align: center;
    margin: 24px 0 0;
  }
  .disclaimer:first-child {
    margin: 0 0 24px;
  }
  .disclaimer >>> * {
    margin: 24px 0 0;
  }
  .disclaimer >>> *:first-child {
    margin: 0;
  }
  .usp {
    display: flex;
    margin-top: 16px;
  }
  .usp-icon {
    background: url(/icons/points.png) no-repeat center / 22px 22px;
    height: 22px;
    width: 22px;
  }
  .usp-text {
    font-size: 16px;
    font-family: 'CircularXXWeb-Medium', sans-serif;
    padding-left: 12px;
  }
  .active-subscription {
    background-color: #F6BF7F;
    color: #fff;
    align-items: center;
    padding: 24px;
  }
  .active-subscription-icon {
    height: 20px;
    width: 28px;
    float: left;
    content: "";
    margin-right: 8px;
    background: url(/icons/mini-logo.png) no-repeat left / contain;
  }
  .active-subscription-title {

    font-size: 16px;
    line-height: 20px;
    font-family: 'CircularXXWeb-Medium', sans-serif;
  }
  .active-subscription-text {
    margin-top: 8px;
    width: 100%;
    max-width: 390px;
  }
  .subscriptions {
    display: flex;
    justify-content: space-between;
  }
  .subscriptions--inActive .subscription {
    cursor: normal;
    pointer-events: none;
    opacity: 0.5;
  }
  .subscription {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #fff;
    width: calc(33.33334% - 8px);
    box-sizing: border-box;
    padding: 32px 8px;
    border-radius: 8px;
    border: 2px solid #fff;
    color: #2C3A43;
    cursor: pointer;
    font-size: 15px;
    box-shadow: 0 6px 8px rgba(8,7,41,0.06);
    transition: 0.2s background-color, 0.2s border-color;
  }
  .subscription:hover {
    background-color: #FDF3E8;
  }
  .subscription.subscription--isActive {
    background-color: #fff;
    border-color: #F6BF7F;
  }
  .subscription-price {
    font-size: 20px;
    font-weight: normal;
    text-align: center;
    line-height: 1;
    font-family: 'CircularXXWeb-Medium', sans-serif;
    padding: 8px 0;
  }
  @media (max-width: 350px) {
    .subscription {
      width: calc(33.33334% - 4px);
    }
    .subscription-price {
      font-size: 16px;
    }
  }
  .applied-coupon {
    color: #fe716f;
  }
  .subscription-label {
    position: absolute;
    top: -14px;
    background-color: #F6BF7F;
    color: #fff;
    height: 28px;
    line-height: 28px;
    padding: 0 10px;
    border-radius: 14px;
  }
  .subscription-link {
    text-align: center;
    margin-top: 16px;
  }
  .subscription-link a {
    color: #000;
    text-decoration: none;
  }
  .subscription-link a:hover {
    opacity: 0.5;
  }
</style>