<template>
  <div>
    <ui-button v-if="manageButton" class="account-button" @click="showAccountModal">{{ loggedIn ? 'Mitt konto' : 'Logga in' }}</ui-button>
    <transition name="window-fade">
      <div v-if="showModal" class="dimming">
        <div class="window">
          <div class="panel root-panel">
            <div v-if="loggedIn == 'not' && myData" class="flex">
              <header>
                <span class="close" @click="showModal = false"><svg width="26" height="26" xmlns="http://www.w3.org/2000/svg"><g fill="#2C3A43" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M20.97 3.97a.75.75 0 011.133.976l-.073.084-17 17a.75.75 0 01-1.133-.976l.073-.084 17-17z"/><path d="M3.97 3.97a.75.75 0 01.976-.073l.084.073 17 17a.75.75 0 01-.976 1.133l-.084-.073-17-17a.75.75 0 010-1.06z"/></g></svg></span>
                <span class="settings" @click="openPanel('profileSettings')"><img width="25" src="icons/nav-settings.png" alt="inställningar"/></span>
              </header>
              <section class="profile">
                <Profile :icon="userData.avatar" :name="userData.name" />
                <div class="profile-name">{{ userData.name ? userData.name.split(' ')[0] : '' }}</div>
              </section>
              <section class="statistics">
                <UserStatistics :stats="userStats" v-if="myStats"/>
              </section>
            </div>
            <div class="panel" v-if="loggedIn && myData">
              <header>
                <span class="close" @click="showModal = false"><svg width="26" height="26" xmlns="http://www.w3.org/2000/svg"><g fill="#2C3A43" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M20.97 3.97a.75.75 0 011.133.976l-.073.084-17 17a.75.75 0 01-1.133-.976l.073-.084 17-17z"/><path d="M3.97 3.97a.75.75 0 01.976-.073l.084.073 17 17a.75.75 0 01-.976 1.133l-.084-.073-17-17a.75.75 0 010-1.06z"/></g></svg></span>
                <h2>Konto</h2>
              </header>
              <section>
                <UserSettings :user="userData" :subscriptionName="subscriptionName" :subscriptions="subscriptions" @trigger-panel="panelTriggered"/>
                <div class="logout">
                  <ui-button :loading="sending" @click="logout">Logga ut</ui-button>
                </div>
              </section>
            </div> 
            <div v-else class="flex">
              <header>
                <span class="close" @click="showModal = false"><svg width="26" height="26" xmlns="http://www.w3.org/2000/svg"><g fill="#2C3A43" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M20.97 3.97a.75.75 0 011.133.976l-.073.084-17 17a.75.75 0 01-1.133-.976l.073-.084 17-17z"/><path d="M3.97 3.97a.75.75 0 01.976-.073l.084.073 17 17a.75.75 0 01-.976 1.133l-.084-.073-17-17a.75.75 0 010-1.06z"/></g></svg></span>
              </header>
              <div class="center">
                <div class="buttons">
                  <ui-button @click="openPanel('emailRegisterForm')">Registrera med e-post</ui-button>
                  <ui-button class="secondary" @click="openPanel('emailLoginForm')">Logga in med e-post</ui-button>
                </div>
                <div class="separator"><span>Eller</span></div>
                <div class="providers">
                  <a :href="loginUrl('apple')" @click="providerLogin('appleLogin', $event)"><svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><rect width="48" height="48" rx="8" stroke="#E3E5EA" transform="translate(-14 -14)"/><g fill="#2C3A43" fill-rule="nonzero"><path d="M17.23 14.806c-.396.875-.584 1.266-1.094 2.04-.71 1.08-1.71 2.426-2.95 2.437-1.101.01-1.385-.716-2.88-.708-1.495.008-1.808.721-2.91.71-1.24-.01-2.187-1.226-2.898-2.307-1.984-3.021-2.192-6.567-.968-8.451.87-1.34 2.243-2.124 3.534-2.124 1.314 0 2.14.72 3.226.72 1.054 0 1.696-.72 3.216-.72 1.149 0 2.366.624 3.232 1.704-2.84 1.558-2.378 5.614.492 6.699zM12.976 4.085c.624-.8 1.097-1.93.925-3.085-1.019.07-2.21.718-2.906 1.562-.63.767-1.152 1.904-.95 3.01 1.113.034 2.262-.63 2.931-1.487z"/></g></g></svg></a>
                  <a :href="loginUrl('facebook')" @click="providerLogin('facebookLogin', $event)"><svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><rect width="48" height="48" rx="8" stroke="#E3E5EA" transform="translate(-14 -14)"/><path d="M18 10.048a8 8 0 10-9.25 7.9V12.36H6.719v-2.312H8.75V8.285a2.822 2.822 0 013.021-3.112c.6.008 1.199.06 1.791.156V7.3h-1.008a1.155 1.155 0 00-1.3 1.25v1.5h2.219l-.355 2.312H11.25v5.591A8 8 0 0018 10.048z" fill="#2C3A43" fill-rule="nonzero"/></g></svg></a>
                  <a :href="loginUrl('google')" @click="providerLogin('googleLogin', $event)"><svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><rect width="48" height="48" rx="8" stroke="#E3E5EA" transform="translate(-14 -14)"/><path d="M17.679 8.545h-7.636v3.273h4.328c-.692 2.182-2.401 2.91-4.363 2.91a4.727 4.727 0 113.035-8.347l2.378-2.265A8 8 0 1010.008 18c4.41 0 8.4-2.909 7.67-9.455z" fill="#2C3A43" fill-rule="nonzero"/></g></svg></a>
                </div>
              </div>
              <div class="disclaimer">
                Genom att skapa ett konto godkänner jag Mindfullys <a class="link" href="https://www.mindfully.nu/personuppgiftspolicy">personuppgiftspolicy</a>
              </div>
            </div>
          </div>
          <transition name="panel-fade">
            <div class="panel" v-if="isPanelOpen('profileSettings')">
              <header>
                <span class="close" @click="closePanel('profileSettings')"><svg width="26" height="26" xmlns="http://www.w3.org/2000/svg"><g fill="#2C3A43" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M23.5 12.75a.75.75 0 01.102 1.493l-.102.007h-22a.75.75 0 01-.102-1.493l.102-.007h22z"/><path d="M8.97 4.97a.75.75 0 011.133.976l-.073.084-7.469 7.47 7.47 7.47a.75.75 0 01.072.976l-.073.084a.75.75 0 01-.976.073l-.084-.073-8-8a.75.75 0 01-.073-.976l.073-.084 8-8z"/></g></svg></span>
                <h2>Inställningar</h2>
              </header>
              <section>
                <div class="list">
                  <div class="list-item">
                    <div class="list-item-info">
                      <div class="list-item-info-label">Konto</div>
                      <div class="list-item-info-value">Uppdatera din profil</div>
                    </div>
                    <div class="list-item-action" @click="openPanel('account')"></div>
                  </div>
                  <div class="list-item">
                    <div class="list-item-info">
                      <div class="list-item-info-label">Om Mindfully</div>
                    </div>
                    <div class="list-item-action" @click="supportAction('about')"></div>
                  </div>
                  <div class="list-item">
                    <div class="list-item-info">
                      <div class="list-item-info-label">Support</div>
                    </div>
                    <div class="list-item-action" @click="supportAction('support')"></div>
                  </div>
                  <div class="list-item">
                    <div class="list-item-info">
                      <div class="list-item-info-label">Personuppgiftspolicy</div>
                    </div>
                    <div class="list-item-action" @click="supportAction('privacy')"></div>
                  </div>
                </div>
                <div class="logout">
                  <ui-button :loading="sending" @click="logout">Logga ut</ui-button>
                </div>
              </section>
            </div>
          </transition>
          <transition name="panel-fade">
            <div class="panel" v-if="isPanelOpen('account')">
              <header>
                <span class="close" @click="closePanel('account')"><svg width="26" height="26" xmlns="http://www.w3.org/2000/svg"><g fill="#2C3A43" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M23.5 12.75a.75.75 0 01.102 1.493l-.102.007h-22a.75.75 0 01-.102-1.493l.102-.007h22z"/><path d="M8.97 4.97a.75.75 0 011.133.976l-.073.084-7.469 7.47 7.47 7.47a.75.75 0 01.072.976l-.073.084a.75.75 0 01-.976.073l-.084-.073-8-8a.75.75 0 01-.073-.976l.073-.084 8-8z"/></g></svg></span>
                <h2>Konto</h2>
              </header>
              <section>
                <UserSettings :user="userData" :subscriptionName="subscriptionName" :subscriptions="subscriptions" @trigger-panel="panelTriggered"/>
              </section>
            </div>
          </transition>
          <transition name="panel-fade">
            <div class="panel bg-gray" v-if="isPanelOpen('survey')">
              <header>
                <span class="close" @click="afterRegisterFlow = false; closePanel('survey')"><svg width="26" height="26" xmlns="http://www.w3.org/2000/svg"><g fill="#2C3A43" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M23.5 12.75a.75.75 0 01.102 1.493l-.102.007h-22a.75.75 0 01-.102-1.493l.102-.007h22z"/><path d="M8.97 4.97a.75.75 0 011.133.976l-.073.084-7.469 7.47 7.47 7.47a.75.75 0 01.072.976l-.073.084a.75.75 0 01-.976.073l-.084-.073-8-8a.75.75 0 01-.073-.976l.073-.084 8-8z"/></g></svg></span>
                <h2>Varför vill du meditera?</h2>
              </header>
              <section>
                <UserSurvey :afterRegisterFlow="afterRegisterFlow" @next="gotoSubscription" @trigger-panel="panelTriggered"/>
              </section>
            </div>
          </transition>
          <transition name="panel-fade">
            <div class="panel" v-if="isPanelOpen('subscriptions')">
              <header>
                <span class="close" @click="afterRegisterFlow = false; closePanel('subscriptions')"><svg width="26" height="26" xmlns="http://www.w3.org/2000/svg"><g fill="#2C3A43" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M23.5 12.75a.75.75 0 01.102 1.493l-.102.007h-22a.75.75 0 01-.102-1.493l.102-.007h22z"/><path d="M8.97 4.97a.75.75 0 011.133.976l-.073.084-7.469 7.47 7.47 7.47a.75.75 0 01.072.976l-.073.084a.75.75 0 01-.976.073l-.084-.073-8-8a.75.75 0 01-.073-.976l.073-.084 8-8z"/></g></svg></span>
                <h2>{{ userData.subscription ? 'Abonnemang' : 'Starta din provperiod' }}</h2>
              </header>
              <section class="subscriptions">
                <UserSubscriptions :afterRegisterFlow="afterRegisterFlow" @skip="afterRegisterFlow = false; closePanel('subscriptions')" :subscriptionName="subscriptionName" :subscription="userData.subscription" :subscriptions="subscriptions"/>
              </section>
            </div>
          </transition>
          <transition name="panel-fade">
            <div class="panel" v-if="isPanelOpen('emailRegisterForm')">
              <header>
                <span class="close" @click="closePanel('emailRegisterForm')"><svg width="26" height="26" xmlns="http://www.w3.org/2000/svg"><g fill="#2C3A43" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M23.5 12.75a.75.75 0 01.102 1.493l-.102.007h-22a.75.75 0 01-.102-1.493l.102-.007h22z"/><path d="M8.97 4.97a.75.75 0 011.133.976l-.073.084-7.469 7.47 7.47 7.47a.75.75 0 01.072.976l-.073.084a.75.75 0 01-.976.073l-.084-.073-8-8a.75.75 0 01-.073-.976l.073-.084 8-8z"/></g></svg></span>
                <h2>Registrera med e-post</h2>
              </header>
              <section>
                <form class="form" @submit.prevent="register">
                  <ui-input id="name" label="Namn" placeholder="Johanna Johansson" v-model="name"></ui-input>
                  <ui-input type="email" id="email" label="E-post" placeholder="namn@domän.se" v-model="email"></ui-input>
                  <ui-input type="password" id="password" label="Lösenord" placeholder="Lösenord" autocomplete="new-password" v-model="password" hintText="Lösenordet ska vara minst 6 tecken och innehålla minst en stor bokstav"></ui-input>
                  
                  <ui-button :loading="sending">Registrera med e-post</ui-button>
                </form>
              </section>
            </div>
          </transition>
          <transition name="panel-fade">
            <div class="panel" v-if="isPanelOpen('emailLoginForm')">
              <header>
                <span class="close" @click="closePanel('emailLoginForm')"><svg width="26" height="26" xmlns="http://www.w3.org/2000/svg"><g fill="#2C3A43" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M23.5 12.75a.75.75 0 01.102 1.493l-.102.007h-22a.75.75 0 01-.102-1.493l.102-.007h22z"/><path d="M8.97 4.97a.75.75 0 011.133.976l-.073.084-7.469 7.47 7.47 7.47a.75.75 0 01.072.976l-.073.084a.75.75 0 01-.976.073l-.084-.073-8-8a.75.75 0 01-.073-.976l.073-.084 8-8z"/></g></svg></span>
                <h2>Logga in med e-post</h2>
              </header>
              <section>
                <form class="form" @submit.prevent="login">
                  <ui-input type="email" id="email" label="E-post" placeholder="namn@domän.se" autocomplete="username" v-model="email"></ui-input>
                  <ui-input type="password" id="password" label="Lösenord" placeholder="Lösenord" autocomplete="current-password" v-model="password"></ui-input>
                  <a href="#" class="form-link" @click="openPanel('forgotPassword')">Glömt lösenord?</a>
                  <ui-button :loading="sending">Logga in</ui-button>
                </form>
              </section>
            </div>
          </transition>
          <transition name="panel-fade">
            <div class="panel" v-if="isPanelOpen('forgotPassword')">
              <header>
                <span class="close" @click="closePanel('forgotPassword')"><svg width="26" height="26" xmlns="http://www.w3.org/2000/svg"><g fill="#2C3A43" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M23.5 12.75a.75.75 0 01.102 1.493l-.102.007h-22a.75.75 0 01-.102-1.493l.102-.007h22z"/><path d="M8.97 4.97a.75.75 0 011.133.976l-.073.084-7.469 7.47 7.47 7.47a.75.75 0 01.072.976l-.073.084a.75.75 0 01-.976.073l-.084-.073-8-8a.75.75 0 01-.073-.976l.073-.084 8-8z"/></g></svg></span>
                <h2>Återställ lösenord</h2>
              </header>
              <section v-if="!code" class="padded">
                Vi skickar en återställningslänk till din e-postadress.
              </section>
              <section class="padded">
                <PasswordReset modal="true" :hashEmail="resetEmail" :hashCode="resetCode"/>
              </section>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import moment from 'moment';
  import Mindfully from './common';
  import PasswordReset from './PasswordReset.vue';
  import UserSettings from './UserSettings.vue';
  import UserStatistics from './UserStatistics.vue';
  import UserSubscriptions from './UserSubscriptions.vue';
  import UserSurvey from './UserSurvey.vue';
  import Profile from './components/Profile.vue';
  import UiButton from './components/Button.vue';
  import UiInput from './components/Input.vue';
  export default {
    name: "AccountManager",
    data() {
      return {
        manageButton: false,
        afterRegisterFlow: false,
        showModal: false,
        openPanels: [],
        sending: false,
        name: '',
        email: '',
        password: '',
        subscriptions: [],
        userData: null,
        userStats: null,
        loggedIn: false,
        resetEmail: null,
        resetCode: null,
        inviteId: null,
        apiUrl: process.env.VUE_APP_API_URL,
        onboardingUpdatedSubscription: false
      }
    },
    components: {
      UserSettings,
      UserStatistics,
      UserSubscriptions,
      UserSurvey,
      PasswordReset,
      Profile,
      UiButton,
      UiInput
    },
    watch: {
      loggedIn: function(value) {
        window.dispatchEvent(new Event('mindfully:loggedIn', { loggedIn: value }));
      }
    },
    computed: {
      myData() {
        if (!this.userData) {
          this.getUserData();
        }
        if (!this.subscriptions || !this.subscriptionName) {
          this.getSubscriptions();
        }
        this.setupUserProperties();
        return this.userData;
      },
      myStats() {
        if (!this.userStats) {
          this.getUserStats();
        }
        return this.userStats;
      },
      isActiveSubscriber() {
        if (this.userData && this.userData.subscription && (!this.userData.subscription.end || moment(this.userData.subscription.end).isAfter(moment()))) {
          return true;
        } else {
          return false;
        }
      },
      subscriptionName() {
        return (this.isActiveSubscriber && this.userData.subscription) ? (this.userData.subscription.product_name ? this.userData.subscription.product_name : this.userData.subscription.product_id) : 'Inget';
      },
      inviteLink() {
        return window.location.origin + (this.inviteId ? '#invite=' + this.inviteId : '');
      }
    },
    methods: {
      createButton() {
        this.manageButton = true;
      },
      loginUrl(provider) {
        let link = this.apiUrl + '/connect/' + provider + '/redirect?callback=' + window.location.href;
        if (link.indexOf('#') !== -1) {
          link = link.split('#')[0];
        }
        
        return link;
      },
      showAccountModal() {
        this.showModal = true;
        if (this.loggedIn && this.myData) {
          Mindfully.sendEvent('profile');
        }
      },
      openPanel(id) {
        if (!this.isPanelOpen(id)) {
          this.openPanels.push(id);
        }
        Mindfully.sendEvent(id);
      },
      closePanel(id) {
        var index = this.openPanels.indexOf(id);
        if (index !== -1) {
          this.openPanels.splice(index, 1);
        }
      },
      panelTriggered(event, panelId) {
        this.openPanel(panelId);
      },
      isPanelOpen(id) {
        return this.openPanels.indexOf(id) !== -1;
      },
      setupUserProperties() {
        Mindfully.mixpanel('identify', this.userData.id);
        var userProps = {
          "$distinct_id": this.userData.id,
          "$name": this.userData.name,
          "$email": this.userData.email,
          "User Created At": this.userData.createdAt
        };
        if (this.userData.group) {
          userProps['Strapi Group'] = this.userData.group.title;
        }
        Mindfully.mixpanel("people.set", userProps);
      },
      providerLogin(providerLoginName, $event) {
        Mindfully.sendEvent(providerLoginName);
        
        if (providerLoginName == "facebookLogin") {
          $event.preventDefault();
          window.FB.login((response) => {
            if (response && response.authResponse && response.authResponse.accessToken) {
              Mindfully.get({
                url: "/auth/facebook/callback?access_token=" + response.authResponse.accessToken + "&token_type=bearer"
              }).then((json) => {
                Mindfully.storeItem('m-ut', json.jwt, 0);
                Mindfully.storeItem('mindfullyUserData', json.user, 60);
                this.userData = json.user;
                this.loggedIn = true;
                //this.getUserStats();

                this.registerInvite(() => {
                  this.registerGroup(() => {
                    this.getSubscriptions(() => {
                      this.sending = false;
                      this.setupUserProperties();
                      if (this.userData && this.userData.createdAt && moment().diff(moment(this.userData.createdAt), 'seconds') < 60) {
                        this.afterRegisterFlow = true;
                        Mindfully.mixpanel('track', 'Complete Registration', {
                          "Provider": "Facebook"
                        });
                        this.openPanel('survey');
                      } else {
                        Mindfully.mixpanel('track', 'Login', {
                          "Provider": "Facebook"
                        });
                      }
                    });
                    //window.location = window.location.origin;
                  })
                });

              }).catch((err) => {
                Mindfully.defaultErrorHandler(err);
                this.sending = false;
              })
            } else {
              alert("Det gick inte att logga in");
            }
          });
          return false;
        }
        return true;
      },
      supportAction(action) {
        const supportActions = {
          'about': 'https://mindfully.nu/',
          'privacy': 'https://mindfully.nu/personuppgiftspolicy',
          'support': 'mailto:support@mindfully.nu'
        };
        Mindfully.sendEvent(action);
        window.open(supportActions[action], '_blank');
      },
      getSubscriptions(next) {
        Mindfully.get({
          url: "/subscriptions?getWebPrices=true&gateway=stripe"
        }).then((json) => {
          const parsedSubscriptions = json.subscriptions.map(sub => {
            return {
              id: sub.id,
              title: sub.title,
              label: sub.discountPercentage != undefined ? "-"+sub.discountPercentage+"%" : sub.discountLabel,
              appleProductId: sub.appleProductID,
              googleProductId: sub.googleProductID,
              stripeProductId: sub.stripePriceID,
              price: sub.price,
              period: sub.period
            }
          });
          Mindfully.storeItem('mindfullySubscriptions', parsedSubscriptions, 24 * 60);
          Mindfully.storeItem('mindfullyTrial', json.trialLength, 24 * 60);
          Mindfully.storeItem('mindfullyCoupon', json.coupon, 24 * 60);
          Mindfully.storeItem('mindfullyDisclaimer', json.disclaimer, 24 * 60);
          this.subscriptions = parsedSubscriptions;
          if (next) {
            next();
          }
        }).catch((err) => {
          if (next) {
            Mindfully.defaultErrorHandler(err);
            next();
          }
        })
      },
      invite() {
        if (this.loggedIn && this.userData.subscription) {
          Mindfully.post({
            url: "/users/invite"
          }).then((json) => {
            this.inviteId = json.invite;
            this.sending = false;
            this.openPanel('invite');
          }).catch((err) => {
            Mindfully.defaultErrorHandler(err);
            this.sending = false;
          })
        } else {
          this.openPanel('invite');
        }
        Mindfully.sendEvent('shareWithFriend');
      },
      getUserData(next) {
        Mindfully.get({
          url: "/users/me"
        }).then((json) => {
          Mindfully.storeItem('mindfullyUserData', json, 60);
          this.sending = false;
          this.editForm = false;
          this.userData = json;
          if (next) {
            next();
          }
        }).catch((err) => {
          Mindfully.defaultErrorHandler(err);
          this.sending = false;
          this.logout();
          if (next) {
            next();
          }
        })
      },
      getUserStats() {
        if (this.sending) return;
        this.sending = true;

        Mindfully.get({
          url: "/statistics"
        }).then((json) => {
          Mindfully.storeItem('mindfullyUserStats', json, 60);
          this.userStats = json;
          this.sending = false;
        }).catch((err) => {
          Mindfully.defaultErrorHandler(err);
          this.sending = false;
        })
      },
      registerInvite(next) {
        const query = window.location.search;
        var inviteCode = Mindfully.fetchItem("inviteCode");
        if ((query.length && query.indexOf('invite=') !== -1) || inviteCode) {
          var inviteId = "";
          if (query.length && query.indexOf('invite=') !== -1) {
            inviteId = query.split('invite=')[1];
          } else if (inviteCode) {
            inviteId = inviteCode;
            localStorage.removeItem("inviteCode");
          }
          inviteId = inviteId.split('&')[0]; // Trim
          Mindfully.post({
            url: "/auth/registerInvite",
            body: { inviteId }
          }).then((json) => {
            if (json.ok) {
              if (json.updatedSubscription) {
                Mindfully.get({
                  url: "/users/me"
                }).then((user) => {
                  if (user) {
                    Mindfully.storeItem('mindfullyUserData', user, 60);
                    this.userData = user;
                    this.onboardingUpdatedSubscription = true;
                    next();
                  } else {
                    console.error(user);
                    next();
                  }
                }).catch((err) => {
                  Mindfully.defaultErrorHandler(err);
                  next();
                })
              } else {
                next();
              }
            } else {
              console.error(json);
              next();
            }
          }).catch((err) => {
            Mindfully.defaultErrorHandler(err);
            next();
          })
        } else {
          next();
        }
      },
      registerGroup(next) {
        if (window.mindfullyAutojoinGroup && window.mindfullyAutojoinGroup.length > 0) {
          Mindfully.post({
            url: "/auth/registerGroup",
            body: { groupId: window.mindfullyAutojoinGroup }
          }).then((json) => {
            if (json.group) {
              if (json.updatedSubscription) {
                Mindfully.get({
                  url: "/users/me"
                }).then((user) => {
                  if (user) {
                    Mindfully.storeItem('mindfullyUserData', user, 60);
                    this.userData = user;
                    this.onboardingUpdatedSubscription = true;
                    next();
                  } else {
                    console.error(user);
                    next();
                  }
                }).catch((err) => {
                  Mindfully.defaultErrorHandler(err);
                  next();
                })
              } else {
                next();
              }
            } else {
              console.error(json);
              next();
            }
          }).catch((err) => {
            Mindfully.defaultErrorHandler(err);
            next();
          })
        } else {
          next();
        }
      },
      gotoSubscription() {
        if (this.onboardingUpdatedSubscription) {
          window.location = process.env.VUE_APP_STRIPE_SUCCESS_CALLBACK;
        } else {
          this.closePanel("survey");
          this.openPanel("subscriptions");
        }
      },
      register() {
        if (this.password.length < 6) {
          alert('Lösenordet måste vara minst 6 tecken långt');
          return false;
        }
        if (!this.password.match(/[A-ZÅÄÖ]/)){
          alert('Lösenordet måste innehålla minst en stor bokstav');
          return false;
        }

        if (this.sending) return;
        this.sending = true;

        Mindfully.sendEvent('emailRegister');

        Mindfully.post({
          url: "/auth/local/register",
          body: {
            name: this.name,
            email: this.email,
            password: this.password
          }
        }).then((json) => {
          Mindfully.storeItem('m-ut', json.jwt, 0);
          Mindfully.storeItem('mindfullyUserData', json.user, 60);
          this.userData = json.user;
          this.loggedIn = true;
          Mindfully.sendEvent('emailRegisterComplete');
          this.registerInvite(() => {
            this.registerGroup(() => {
              this.getSubscriptions(() => {
                this.sending = false;
                this.setupUserProperties();
                Mindfully.mixpanel('track', 'Complete Registration', {
                  "Provider": "Email"
                });
                this.closePanel("emailRegisterForm");
                this.afterRegisterFlow = true;
                this.openPanel('survey');
              })
              // window.location = process.env.VUE_APP_REGISTER_REDIRECT;
            });
          });
        }).catch((err) => {
          Mindfully.defaultErrorHandler(err);
          this.sending = false;
        })
      },
      login() {
        if (this.sending) return;
        this.sending = true;

        Mindfully.post({
          url: "/auth/local",
          body: {
            identifier: this.email,
            password: this.password
          }
        }).then((json) => {
          Mindfully.storeItem('m-ut', json.jwt, 0);
          Mindfully.storeItem('mindfullyUserData', json.user, 60);
          this.userData = json.user;
          this.loggedIn = true;
          this.getSubscriptions(() => {
            this.sending = false;
            this.setupUserProperties();
            this.closePanel('emailLoginForm')
            Mindfully.mixpanel('track', 'Login', {
              "Provider": "Email"
            });
            Mindfully.sendEvent('emailLogin');
          });
        }).catch((err) => {
          Mindfully.defaultErrorHandler(err);
          this.sending = false;
        })
      },
      logout() {
        this.userData = null;
        this.userStats = null;

        localStorage.removeItem('m-ut');
        localStorage.removeItem('mindfullyUserData');
        localStorage.removeItem('mindfullyUserStats');
        localStorage.removeItem('mindfullySubscriptions');
        localStorage.removeItem('mindfullyTrial');
        localStorage.removeItem('mindfullyCoupon');

        this.openPanels = [];
        this.showModal = false;
        this.loggedIn = false;

        Mindfully.mixpanel('track', 'Logout');
        Mindfully.sendEvent('logout');
      }
    },
    mounted() {
      var hash = (window.originalLocationHash && window.originalLocationHash.length > 0) ? window.originalLocationHash : window.location.hash;
      if (hash.length !== 0) {
        // Stripe subscription successful callback is handled in main.js for immediate redirect
        // Directly open the modal
        if (hash.indexOf('open') !== -1) {
          this.showModal = true;
        }
        // Apple signin
        if (hash.indexOf('#token') !== -1) {
          console.log("Apple");
          Mindfully.storeItem('m-ut', hash.split('token=')[1].split('&')[0], 0);
          Mindfully.get({
            url: "/users/me"
          }).then((json) => {
            Mindfully.storeItem('mindfullyUserData', json, 60);
            this.sending = false;
            this.userData = json.user;
            this.loggedIn = true;
            this.showModal = true;
            this.registerInvite(() => {
              this.registerGroup(() => {
                this.getSubscriptions(() => {
                  this.setupUserProperties();
                  if (this.userData && this.userData.createdAt && moment().diff(moment(this.userData.createdAt), 'seconds') < 60) {
                    Mindfully.mixpanel('track', 'Complete Registration', {
                      "Provider": "Apple"
                    });
                    this.afterRegisterFlow = true;
                    this.openPanel('survey');
                    //this.openPanel('subscriptions');
                  } else {
                    Mindfully.mixpanel('track', 'Login', {
                      "Provider": "Apple"
                    });
                  }
                })
              })
            });
          });
        }
        // User has invite
        if (hash.indexOf('invite') !== -1) {
          Mindfully.storeItem("inviteCode", hash.split('#invite=')[1], 60);
        }
        // Reset password in modal
        if (hash.indexOf('reset:') !== -1) {
          const rawHashEmail = hash.split('email=')[1];
          const rawHashCode = hash.split('code=')[1];
          if (rawHashCode) {
            if (rawHashEmail) {
              this.resetEmail = rawHashEmail.split('&')[0]; // Trim string
            }
            this.resetCode = rawHashCode.split('&')[0]; // Trim string
            this.showModal = true;
            this.openPanel('forgotPassword');
          }
        }
        // Other 3rd party  providers signin
        if (hash.indexOf('provider:') !== -1) {
          const successString = hash.split('#provider:')[1];
          const stringParts = successString.split('?');
          const provider = stringParts[0];
          const data = stringParts[1];
          if (data.indexOf('error') !== -1) {
            alert(decodeURIComponent(data).split('&')[0]);
          } else {
            Mindfully.get({
              url: "/auth/" + provider + "/callback?" + data
            }).then((json) => {
              Mindfully.storeItem('m-ut', json.jwt, 0);
              Mindfully.storeItem('mindfullyUserData', json.user, 60);
              this.sending = false;
              this.userData = json.user;
              this.loggedIn = true;
              this.showModal = true;
              this.registerInvite(() => {
                this.registerGroup(() => {
                  this.getSubscriptions(() => {
                    this.setupUserProperties();
                    if (this.userData && this.userData.createdAt && moment().diff(moment(this.userData.createdAt), 'seconds') < 60) {
                      Mindfully.mixpanel('track', 'Complete Registration', {
                        "Provider": provider.charAt(0).toUpperCase() + provider.slice(1)
                      });

                      this.afterRegisterFlow = true;
                      this.openPanel('survey');
                      //this.openPanel('subscriptions');
                    } else {
                      Mindfully.mixpanel('track', 'Login', {
                        "Provider": provider.charAt(0).toUpperCase() + provider.slice(1)
                      });
                    }
                  })
                  //window.location = window.location.origin;
                })
              });
            }).catch((err) => {
              Mindfully.defaultErrorHandler(err);
              this.sending = false;
            })
          }
        }
        window.originalLocationHash = '';
        window.location.hash = '';
      }
      const token = Mindfully.fetchItem('m-ut');
      if (token && token.length) {
        this.loggedIn = true;
      }
      this.userData = Mindfully.fetchItem('mindfullyUserData');
      this.userStats = Mindfully.fetchItem('mindfullyUserStats');
      this.subscriptions = Mindfully.fetchItem('mindfullySubscriptions');
    }
  }
</script>

<style scoped>
  .link {
    color: inherit;
  }
  .link-primary {
    color: #F6BF7F;
    text-decoration: none;
  }
  .center {
    text-align: center;
  }
  .wow {
    font-size: 32px;
    font-family: 'CircularXXWeb-Medium', sans-serif;
    color: #F6BF7F;
  }
  .wow-unit {
    margin-top: 10px;
  }
  button {
    margin-top: 20px;
    width: 100%;
  }
  .account-button {
    margin-top: 0;
  }
  header {
    padding: 32px 24px 24px;
  }
  header .close {
    display: inline-block;
    margin: -4px;
    padding: 4px;
    cursor: pointer;
  }
  header .close svg {
    display: block;
  }
  header .settings {
    cursor: pointer;
  }
  header h2 {
    color: #2C3A43;
    margin: 5px 0 0;
    font: normal 32px/36px 'CircularXXWeb-Medium', sans-serif;
  }
  section + section {
    margin-top: 20px;
  }
  .dimming {
    background-color: rgba(0,0,0,0.15);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 9999999;
  }
  .window {
    background: #fff;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 480px;
    max-height: 800px;
    overflow: hidden;
    box-shadow: 0 6px 20px rgba(8,7,41,0.06);
    color: #2C3A43;
    font: normal 15px/20px 'CircularXXWeb-Book', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @media (min-width: 500px) {
    .window {
      top: 10%;
      bottom: 10%;
    }
  }
  .panel {
    display: flex;
    flex-direction: column;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    z-index: 2;
    box-shadow: 0 6px 20px rgba(8,7,41,0.06);
  }
  .panel.bg-gray {
    background: #f2f2f4;
  }
  .panel-fade-enter-active,
  .panel-fade-leave-active {
    transition: transform .4s, opacity .4s;
  }
  .panel-fade-enter-from,
  .panel-fade-leave-to {
    transform: translateX(100%);
    opacity: 0;
  }
  .root-panel {
    transform: none;
    z-index: 1;
    opacity: 1;
    overflow-x: hidden;
  }
  .window-fade-enter-active,
  .window-fade-leave-active {
    transition: background-color 0.4s ease;
  }
  .window-fade-enter-from,
  .window-fade-leave-to {
      background-color: rgba(0,0,0,0);
  }
  .window-fade-enter-active .window,
  .window-fade-leave-active .window {
    transition: opacity 0.4s ease, transform 0.4s ease;
  }
  .window-fade-enter-from .window,
  .window-fade-leave-to .window {
      opacity: 0;
      transform: scale(0.8, 0.8) translateY(50px);
  }

  .flex {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .flex .buttons {
    padding: 0 40px;
  }
  .flex .buttons button {
    display: block;
    width: 100%;
    margin: 24px 0;
  }
  .flex .disclaimer {
    padding: 65px 48px;
    color: #838F9E;
    font: normal 13px/18px 'CircularXXWeb-Book', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
  }
  .flex .separator {
    position: relative;
    text-align: center;
  }
  .flex .separator:before {
    position: absolute;
    content: "";
    height: 1px;
    background: #E3E5EA;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
  }
  .flex .separator span {
    color: #838F9E;
    font: normal 13px/18px 'CircularXXWeb-Book', sans-serif;
    position: relative;
    z-index: 2;
    display: inline-block;
    padding: 0 16px;
    background: #fff;
  }

  .list-item {
    display: flex;
    padding: 0 24px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E3E5EA;
  }
  .list-item:first-child {
    border-top: 1px solid #E3E5EA;
  }
  .list-item-info {
    padding: 20px 0;
  }
  .list-item-info-label {
    font-family: 'CircularXXWeb-Medium', sans-serif;
  }
  .list-item-info-value {
    height: 20px;
    margin-top: 4px;
    color: #838F9E;
  }
  .list-item-action:not(:empty) {
    width: 20px;
    height: 20px;
  }
  .list-item-action:empty {
    width: 10px;
    height: 10px;
    border-style: solid;
    border-color: #838F9E;
    border-width: 0;
    border-right-width: 2px;
    border-bottom-width: 2px;
    transform: rotate(-45deg);
  }
  .list-item-action:hover {
    opacity: 0.5;
    cursor: pointer;
  }

  .providers {
    margin: 24px 0 0;
    text-align: center;
  }
  .providers a {
    display: inline-block;
    padding: 13px;
    border-radius: 8px;
    margin: 0 12px;
    border: 1px solid #E3E5EA;
  }
  .providers svg {
    display: block;
  }
  .profile-name {
    font-size: 32px;
    line-height: 2;
    text-align: center;
  }
  .padded {
    padding: 0 24px;
  }
  .form {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
  }
  .form-link {
    color: #F6BF7F;
    margin: 20px 0;
    text-decoration: none;
  }
  .logout {
    padding: 24px;
  }
  .logout button {
    width: 100%;
    margin: 0;
  }
  .statistics {
    flex-grow: 1;
    background-color: #f2f2f4;
    padding: 24px;
  }
  .settings {
    position: absolute;
    right: 24px;
  }
  .subscriptions {
    flex-grow: 1;
    display: flex;
  }
  .panel-inner {
    padding: 0 24px;
  }
  .inviteLink {
    color: #000;
  }
  .inviteLink:hover {
    opacity: 0.5;
  }
  .inviteLink strong {
    word-break: break-all;
  }
  @media (max-width: 499px) {
    .account-button {
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(1 1)' stroke='%23fff' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M16 18v-2a4 4 0 00-4-4H4a4 4 0 00-4 4v2'/%3E%3Ccircle cx='8' cy='4' r='4'/%3E%3C/g%3E%3C/svg%3E");
      text-indent: -99999px;
      width: 48px;
    }
  }
</style>
