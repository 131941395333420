<template>
  <div class="profile">
    <img v-if="icon" alt="Avatar" :src="icon.url"/>
    <div v-else class="profile-name">{{ name ? name[0] : '' }}</div>
  </div>
</template>

<script>

export default {
  name: 'Profile',
  props: {
    icon: Object,
    name: String
  }
}
</script>

<style scoped>
  .profile {
    position: relative;
    background-color: #fcf1e2;
    width: 120px;
    height: 120px;
    padding: 0px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid white;
    box-shadow: 0 8px 11px rgba(27,15,24,0.15);
  }
  .profile-name {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #F6BF7F;
    font-size: 32px;
    line-height: 2;
    text-align: center;
  }
  .profile img {
    width: 100%;
    object-fit: cover;
  }
</style>
