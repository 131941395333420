<template>
  <div>
    <div class="card">
      <div class="card-row">
        <div class="card-column">
          <div class="card-column-item">
            <div class="wow">{{ Math.round(stats.total/60) }}</div>
            <div class="wow-unit">Minuter</div>
          </div>
        </div>
        <div class="card-column">
          <div class="card-column-item">
            <div class="wow">{{ stats.count }}</div>
            <div class="wow-unit">Tillfällen</div>
          </div>
        </div>
      </div>
      <div class="card-row">
        <div class="card-column">
          <div class="card-column-item card-column-button">
            <div class="center">
              <ui-button type="link" @click="share">
                <img class="icon" width="18" alt="Dela" src="icons/icon-share-2.png"/>
                Dela din statistik
              </ui-button>
              <ui-alert header="Min statistik" v-if="alertDisplay" :text="alertText" @close-alert="this.alertDisplay = false"></ui-alert>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-row">
        <div class="card-column">
          <div class="card-column-item">
            <div class="counter">
              <div>
                <span class="wow">{{ stats.dayStreak }}</span>
                <span>&nbsp;Dagar i rad</span>
              </div>
              <div>
                <span>{{ stats.recordStreak }}</span>
                <span>&nbsp;Högst</span>
              </div>
              <div class="counter-progress">
                <div class="counter-progress-inner" :style="streakProgress"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Mindfully from './common';
  import UiAlert from './components/Alert.vue';
  import UiButton from './components/Button.vue';
  export default {
    name: "UserStatistics",
    props: {
      stats: Object
    },
    data() {
      return {
        alertDisplay: false,
        alertText: ''
      }
    },
    components: {
      UiAlert,
      UiButton
    },
    computed: {
      streakProgress() {
        return 'width:' + (this.stats.dayStreak / this.stats.recordStreak * 100) + '%';
      }
    },
    methods: {
      share() {
        Mindfully.sendEvent('shareStatistics');
        const shareText = "Min statistik från Mindfully-appen:<br /><br />Minuter: " + Math.round(this.stats.total/60) + "<br />Tillfällen: " + this.stats.count + "<br />Dagar i rad: " + this.stats.dayStreak + "<br />Flest dagar i rad: " + this.stats.recordStreak;
        this.alertText = shareText;
        this.alertDisplay = true;
      }
    }
  }
</script>

<style scoped>
  .center {
    text-align: center;
  }
  .middle {
    vertical-align: middle;
  }
  .link {
    color: inherit;
  }
  .link-primary {
    color: #F6BF7F;
    text-decoration: none;
  }
  .wow {
    font-size: 32px;
    font-weight: 700;
    color: #F6BF7F;
  }
  .wow-unit {
    margin-top: 10px;
  }
  .card {
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 6px 20px rgba(8,7,41,0.06);
  }
  .card + .card {
    margin-top: 16px;
  }
  .card-row {
    display: flex;
  }
  .card-row +.card-row {
    border-top: 1px solid #f2f2f4;
  }
  .card-column {
    flex-grow: 1;
  }
  .card-column + .card-column {
    border-left: 1px solid #f2f2f4;
  }
  .card-column-item {
    padding: 24px;
  }
  .card-column-button {
    padding: 1px;
  }
  .card-column-button button {
    width: 100%;
  }
  .counter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
  }
  .counter-progress {
    width: 100%;
    height: 8px;
    margin-top: 10px;
    border-radius: 4px;
    background-color: #f2f2f4;
  }
  .counter-progress-inner {
    width: 0px;
    height: 8px;
    border-radius: 4px;
    background-color: #F6BF7F;
  }
</style>