<template>
  <form @submit.prevent="sendForm">
    <p v-if="code">Ange ett nytt lösenord till ditt konto</p>
    <ui-input v-if="showEmailField" id="username" label="E-post" v-model="email" type="email" placeholder="namn@domän.se" :disabled="!!code" />
    <ui-input v-if="code" id="password" label="Nytt lösenord" type="password" v-model="newPassword" hint-text="Lösenordet ska vara minst 6 tecken och innehålla minst en stor bokstav" />
    <ui-button :loading="sending">Skicka</ui-button>
  </form>
</template>

<script>
  import Mindfully from './common';
  import UiButton from './components/Button.vue';
  import UiInput from './components/Input.vue';

  function getQueryStringParameterByName(name, url = window.location.href) {
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  export default {
    name: "PasswordReset",
    props: {
      modal: Boolean,
      hashEmail: String,
      hashCode: String
    },
    data() {
      return {
        email: getQueryStringParameterByName("email") || this.hashEmail,
        code: getQueryStringParameterByName("code") || this.hashCode,
        newPassword: "",
        newPasswordConfirm: "",
        sending: false
      }
    },
    components: {
      UiButton,
      UiInput
    },
    computed: {
      showEmailField() {
        return !this.code || this.email
      }
    },
    methods: {
      sendForm() {
        if (this.sending) return;
        this.sending = true;
        if (this.code) {
          if (this.newPassword.length < 6) {
            alert('Lösenordet måste vara minst 6 tecken långt');
            this.sending = false;
            return;
          }
          if (!this.newPassword.match(/[A-ZÅÄÖ]/)){
            alert('Lösenordet måste innehålla minst en stor bokstav');
            this.sending = false;
            return;
          }
          Mindfully.sendEvent('reset-password');

          // There's a recovery code available
          Mindfully.post({
            url: "/auth/reset-password",
            skipAuth: true,
            body: {
              code: this.code,
              password: this.newPassword,
              passwordConfirmation: this.newPassword
            }
          }).then(() => {
            this.code = "";
            this.newPassword = "";
            this.sending = false;
            alert("Ditt lösenord har sparats");
          }).catch((err) => {
            Mindfully.defaultErrorHandler(err);
            this.sending = false;
          })
        } else {
          // No recovery code is available, so this is a request for a reset mail
          window.location.hash = '';
          Mindfully.post({
            url: "/auth/forgot-password",
            skipAuth: true,
            body: {
              email: this.email,
              resetUrl: window.location.href + (this.modal ? 'reset:' : '')
            }
          }).then(() => {
            alert("En återställningslänk har skickats till den e-post du angav");
            this.email = "";
            this.sending = false;
          }).catch((err) => {
            Mindfully.defaultErrorHandler(err);
            this.sending = false;
          })
        }

      }
    }
  }
</script>

<style scoped>
  form {
    color: #2C3A43;
    max-width: 300px;
    font: normal 15px/20px 'CircularXXWeb-Book', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-bottom: 40px;
  }
  .input-field + .input-field {
    margin-top: 24px;
  }
  button {
    margin-top: 24px;
  }
</style>
